// import libs
import { React } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ForgetPasswordPage, LoginPage, OtpVerificationPage, ResetPasswordPage } from 'pages/Auth';
import { DriverResetPasswordPage } from 'pages/Driver';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import { DashboardPage } from 'pages/Dashboard/DashboardPage';
import MasterRoutes from 'pages/Masters/index';
import { EditProfilePage, ChangePasswordPage } from 'pages/Accounts/';
import ParameterRoutes from 'pages/Parameters/index';
import HomePageRoutes from 'pages/Settings/HomePage/index';
import AdminSettingRoutes from 'pages/Settings/AdminSetting/index';
import CompanyRoutes from 'pages/Company/index';
import DriverRoutes from 'pages/Driver/index';
import { useGetAdminGeneralSettingData } from 'hooks';
import { settingData } from 'store/features/settingSlice';
import { PageNotFound } from 'common/components';
import { useDispatch } from 'react-redux';
import { addSetting } from 'store/features/settingSlice';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();

  // /**
  //  * This function will cal on page load, and will set data to redux store
  //  */
  /**
   * !This API will call when user click on sync button
   */

  useGetAdminGeneralSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        admin_setting_dahsboard_logo: general_data.admin_setting_dahsboard_logo,
        admin_setting_email_logo: general_data.admin_setting_email_logo,
        admin_setting_favicon_logo: general_data?.admin_setting_favicon_logo,
        admin_setting_login_logo: general_data?.admin_setting_login_logo,

        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });

  /**
   * Getting data from redux store if already exist
   */
  const getSettingData = useSelector(settingData);
  return (
    <Router basename={'/'}>
      <Helmet>
        <title>AOT Delivery </title>
        <link rel="icon" href={`${getSettingData.admin_setting_favicon_logo}`} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path="/login" element={<LoginPage t={t} />} />
          <Route exact path="/forget-password" element={<ForgetPasswordPage t={t} />} />
          <Route exact path="/otp-verification" element={<OtpVerificationPage t={t} />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage t={t} />} />
          <Route
            exact
            path="/driver/reset-password/:time/:driver_user_id"
            element={<DriverResetPasswordPage t={t} />}
          />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<DashboardPage t={t} />} />
          <Route exact path="/edit-profile" element={<EditProfilePage t={t} />} />
          <Route exact path="/change-password" element={<ChangePasswordPage t={t} />} />
          {/* Master Routes */}
          <Route path="/master/*" element={<MasterRoutes t={t} />} />
          {/* Parameter Routes */}
          <Route path="/parameter/*" element={<ParameterRoutes t={t} />} />
          {/* Settings -> Home Page */}
          <Route path="/settings/*" element={<HomePageRoutes t={t} />} />
          {/* Settings -> Admin General Setting Page */}
          <Route path="/settings/general-settings/*" element={<AdminSettingRoutes t={t} />} />
          {/* Company Routes */}
          <Route path="/company/*" element={<CompanyRoutes t={t} />} />
          {/* Driver Routes */}
          <Route path="/driver/*" element={<DriverRoutes t={t} />} />
        </Route>
        <Route>
          {/* Page Not Found Routes */}
          <Route path="*" element={<PageNotFound t={t} />} from="admin" />
        </Route>
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export default PagesRoutes;
