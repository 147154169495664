import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { TranslationService } from 'api';

/**
 * Hook for Translations
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(TranslationService.addTranslation, {
    onSuccess,
    onError,
  });
};

const useListTranslation = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['translation-list', [params]],
    () => TranslationService.listTranslation(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useSyncTranslation = ([isEnabled], onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-sync'], TranslationService.syncTranslation, {
    onSuccess,
    onError,
    enabled: isEnabled,
    retry: 0,
  });
};
const useViewTranslation = (translation_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'translation-view',
    () => TranslationService.viewTranslation({ translation_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdateTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(TranslationService.updateTranslation, {
    onSuccess,
    onError,
  });
};

const useTranslationDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(TranslationService.deleteTranslation, {
    onSuccess,
    onError,
  });
};
export {
  useAddTranslation,
  useListTranslation,
  useSyncTranslation,
  useUpdateTranslation,
  useViewTranslation,
  useTranslationDelete,
};
