import client from 'libs/HttpClient';

class SettingsServices {
  static getSettingData() {
    return client.get('/homepage/setting/get');
  }
  static storeSettingData(request) {
    return client.post('/homepage/setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static getAdminSettingData() {
    return client.get('/admin-setting/get');
  }

  static storeAdminSettingData(request) {
    return client.post('/admin-setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static getAdminGeneralSettingData() {
    return client.get('/get-admin-setting-data');
  }
}
export { SettingsServices };
