import client from 'libs/HttpClient';

class SmsService {
  static addSms(request) {
    return client.put('/sms/store', request);
  }
  static updateSms(request) {
    return client.put(`/sms/update/${request.sms_id}`, request);
  }
  static viewSms(request) {
    return client.get(`/sms/view/${request.sms_id}`, request);
  }
  static addSmsScheduled(request) {
    return client.put('/sms-scheduled/store', request);
  }
  static updateSmsScheduled(request) {
    return client.put(`/sms-scheduled/update/${request.sms_scheduled_id}`, request);
  }
  static viewSmsScheduled(request) {
    return client.get(`/sms-scheduled/view/${request.sms_scheduled_id}`, request);
  }
  static listSms(params) {
    return client.get(`/sms/index`, { params });
  }
  static listSmsScheduled(params) {
    return client.get(`/sms-scheduled/index`, { params });
  }
  static deleteSms(params) {
    return client.delete(`/sms/delete`, { params });
  }
  static deleteSmsScheduled(params) {
    return client.delete(`/sms-scheduled/delete`, { params });
  }
}
export { SmsService };
