import client from 'libs/HttpClient';

class UserService {
  static getProfile(request) {
    return client.get('user/get-profile', request);
  }
  static updatePassword(request) {
    return client.put('user/change-password', request);
  }
  static updateEmail(request) {
    return client.put('user/add-new-email', request);
  }
  static updateEmailVerify(request) {
    return client.put('user/verify-new-email', request);
  }
  static resendOtp(request) {
    return client.put('user/verify-resend-email', request);
  }
  static logOutUser() {
    return client.post('user/logout');
  }
  static updateProfile(request) {
    return client.post('user/update-profile', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static updateProfileImage(request) {
    return client.post('user/upload-image', request);
  }
}

export { UserService };
