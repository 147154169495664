import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { HolidayService } from 'api';

/**
 * Hooks for CMS pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddHoliday = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidayService.addHoliday, {
    onSuccess,
    onError,
  });
};

const useListHoliday = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['holiday-list', [params]], () => HolidayService.listHoliday(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewHoliday = (holiday_id, onSuccess, onError = onDefaultError) => {
  return useQuery('holiday-view', () => HolidayService.viewHoliday({ holiday_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateHoliday = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidayService.updateHoliday, {
    onSuccess,
    onError,
  });
};
const useHolidayDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidayService.deleteHoliday, {
    onSuccess,
    onError,
  });
};
const useHolidayStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidayService.updateStatusHoliday, {
    onSuccess,
    onError,
  });
};
const useListAllHoliday = (request, onSuccess, onError = onDefaultError) => {
  console.warn('come');
  return useQuery(['holiday-list-all', [request]], () => HolidayService.listAllHoliday(request), {
    onSuccess,
    onError,
  });
};

export {
  useListHoliday,
  useAddHoliday,
  useViewHoliday,
  useUpdateHoliday,
  useHolidayDelete,
  useHolidayStatusChange,
  useListAllHoliday,
};
