import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { DriverService } from 'api';

/**
 * Hooks Driver pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddDriverInfo = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.addDriverInfo, {
    onSuccess,
    onError,
  });
};

const useListDriverInfo = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['driver-info-list', [params]], () => DriverService.listDriverInfo(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewDriverInfo = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery('driver-info-view', () => DriverService.viewDriverInfo({ user_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateDriverInfo = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.updateDriverInfo, {
    onSuccess,
    onError,
  });
};
const useDriverDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.deleteDriverInfo, {
    onSuccess,
    onError,
  });
};
const useDriverInfoStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.updateStatusDriverInfo, {
    onSuccess,
    onError,
  });
};
const useViewDriverData = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery('driver-info-view-data', () => DriverService.viewDriverData({ user_id }), {
    onSuccess,
    onError,
  });
};

const useUpdateDriverEmail = (onSuccess, onError) => {
  return useMutation(DriverService.updateDriverEmail, {
    onSuccess,
    onError,
  });
};
const useUpdateDriverEmailVerify = (onSuccess, onError) => {
  return useMutation(DriverService.updateDriverEmailVerify, {
    onSuccess,
    onError,
  });
};
const useResendDriverEamilOtp = (onSuccess, onError) => {
  return useMutation(DriverService.resendOtp, {
    onSuccess,
    onError,
  });
};

export {
  useListDriverInfo,
  useAddDriverInfo,
  useViewDriverInfo,
  useUpdateDriverInfo,
  useDriverDelete,
  useDriverInfoStatusChange,
  useViewDriverData,
  useUpdateDriverEmail,
  useUpdateDriverEmailVerify,
  useResendDriverEamilOtp,
};
