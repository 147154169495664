import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { HomeHealthCareService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Settings sections
 */

const useListHomeHealthCare = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['healthcare-list', [params]],
    () => HomeHealthCareService.listHealthCareService(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddHealthCare = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthCareService.addHomePageHealthCare, {
    onSuccess,
    onError,
  });
};

const useUpdateHomeHealthcare = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthCareService.updateHomePageHealthcare, {
    onSuccess,
    onError,
  });
};

const useHomeHealthCareStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthCareService.updateHomeHealthCareStatusService, {
    onSuccess,
    onError,
  });
};

const useHomeHealthCareDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthCareService.deleteHomeHealthCare, {
    onSuccess,
    onError,
  });
};

const useViewHealthCare = (home_page_heathcare_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'healthcare-view',
    () => HomeHealthCareService.viewHealthCare({ home_page_heathcare_id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useUpdateHealthcare = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthCareService.updateHealthCare, {
    onSuccess,
    onError,
  });
};

export {
  useListHomeHealthCare,
  useUpdateHomeHealthcare,
  useHomeHealthCareStatusChange,
  useHomeHealthCareDelete,
  useAddHealthCare,
  useViewHealthCare,
  useUpdateHealthcare,
};
