import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CmsRoutes from 'pages/Masters/Cms/CmsRoutes';
import EmailTemplateRoutes from 'pages/Masters/EmailTemplate/EmailTemplateRoutes';
import TranslationRoutes from 'pages/Masters/Translations/TranslationRoutes';
import SmsRoutes from 'pages/Masters/Sms/SmsRoutes';
import SmsScheduledRoutes from 'pages/Masters/SmsScheduled/SmsScheduledRoutes';

const MasterRoutes = ({ t }) => {
  return (
    <Routes>
      {/* Cms Routes */}
      <Route path="/cms/*" element={<CmsRoutes t={t} />} />
      {/* Master Email Template Routes */}
      <Route path="/email-template/*" element={<EmailTemplateRoutes t={t} />} />
      {/* Translations Routes */}
      <Route path="/translations/*" element={<TranslationRoutes t={t} />} />
      {/* Sms Routes */}
      <Route path="/sms/*" element={<SmsRoutes t={t} />} />
      {/* Sms Scheduled Routes */}
      <Route path="/sms-scheduled/*" element={<SmsScheduledRoutes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
MasterRoutes.propTypes = {
  t: PropTypes.func,
};
export default MasterRoutes;
