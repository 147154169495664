import client from 'libs/HttpClient';

class PriceOptionService {
  static addPriceOption(request) {
    return client.post('/price-option/store', request);
  }
  static updatePriceOption(request) {
    return client.put(`/price-option/update/${request.price_option_id}`, request);
  }
  static listPriceOption(params) {
    return client.get(`/price-option/index`, { params });
  }
  static viewPriceOption(request) {
    return client.get(`/price-option/view/${request.price_option_id}`, request);
  }
  static deletePriceOption(params) {
    return client.delete(`/price-option/delete`, { params });
  }
  static updateStatusPriceOption(request) {
    return client.put(`/price-option/status-update`, request);
  }
  static updateSetDefaultPriceOption(request) {
    return client.put(`/price-option/set-default`, request);
  }
  static dropdownPriceOptionList(request) {
    return client.get(`/price-option/option-list`, request);
  }
}
export { PriceOptionService };
