import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Service Pages and routes
 */
const ListServicePage = React.lazy(() => import('./ListServicePage'));
const AddServicePage = React.lazy(() => import('./AddServicePage'));
const EditServicePage = React.lazy(() => import('./EditServicePage'));

const ServiceRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListServicePage t={t} />} />
      <Route exact path="/add" element={<AddServicePage t={t} />} />
      <Route exact path="/edit/:service_id" element={<EditServicePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ServiceRoutes.propTypes = {
  t: PropTypes.func,
};
export default ServiceRoutes;
