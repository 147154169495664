import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { PriceOptionService } from 'api';

/**
 * Hooks for Price Option pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddPriceOption = (onSuccess, onError = onDefaultError) => {
  return useMutation(PriceOptionService.addPriceOption, {
    onSuccess,
    onError,
  });
};

const useListPriceOption = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['price-option-list', [params]],
    () => PriceOptionService.listPriceOption(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useViewPriceOption = (price_option_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'price-option-view',
    () => PriceOptionService.viewPriceOption({ price_option_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdatePriceOption = (onSuccess, onError = onDefaultError) => {
  return useMutation(PriceOptionService.updatePriceOption, {
    onSuccess,
    onError,
  });
};
const usePriceOptionDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(PriceOptionService.deletePriceOption, {
    onSuccess,
    onError,
  });
};
const usePriceOptionStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(PriceOptionService.updateStatusPriceOption, {
    onSuccess,
    onError,
  });
};
const usePriceOptionSetDefault = (onSuccess, onError = onDefaultError) => {
  return useMutation(PriceOptionService.updateSetDefaultPriceOption, {
    onSuccess,
    onError,
  });
};
const useDropdownPriceOptionList = (onSuccess, onError = onDefaultError) => {
  return useQuery('option-list', () => PriceOptionService.dropdownPriceOptionList(), {
    onSuccess,
    onError,
  });
};
export {
  useListPriceOption,
  useAddPriceOption,
  useViewPriceOption,
  useUpdatePriceOption,
  usePriceOptionDelete,
  usePriceOptionStatusChange,
  usePriceOptionSetDefault,
  useDropdownPriceOptionList,
};
