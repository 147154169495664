import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { HomeTestimonialService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Testimonial sections
 */

const useListTestimonial = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['testimonial-list', [params]],
    () => HomeTestimonialService.listTestimonial(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddTestimonial = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeTestimonialService.addHomePageTestimonial, {
    onSuccess,
    onError,
  });
};

const useUpdateTestimonial = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeTestimonialService.updateTestimonial, {
    onSuccess,
    onError,
  });
};

const useTestimonialStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeTestimonialService.updateHomeTestimonialStatus, {
    onSuccess,
    onError,
  });
};

const useTestimonialDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeTestimonialService.deleteHomeTestimonial, {
    onSuccess,
    onError,
  });
};

const useViewTestimonial = (testimonial_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'testimonial-view',
    () => HomeTestimonialService.viewTestimonial({ testimonial_id }),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListTestimonial,
  useAddTestimonial,
  useUpdateTestimonial,
  useTestimonialStatusChange,
  useTestimonialDelete,
  useViewTestimonial,
};
