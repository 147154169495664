import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Rate Pages and routes
 */
const ListRatePage = React.lazy(() => import('./ListRatePage'));
const AddRatePage = React.lazy(() => import('./AddRatePage'));
const EditRatePage = React.lazy(() => import('./EditRatePage'));

const RateRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListRatePage t={t} />} />
      <Route exact path="/add" element={<AddRatePage t={t} />} />
      <Route exact path="/edit/:rate_id" element={<EditRatePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
RateRoutes.propTypes = {
  t: PropTypes.func,
};
export default RateRoutes;
