import client from 'libs/HttpClient';

class ServiceTypeService {
  static addService(request) {
    return client.post('/service/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static listService(params) {
    return client.get(`/service/index`, { params });
  }
  static viewService(request) {
    return client.get(`/service/view/${request.service_id}`, request);
  }
  static updateService(request) {
    return client.post(`/service/update/${request.service_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteService(params) {
    return client.delete(`/service/delete`, { params });
  }
  static updateStatusService(request) {
    return client.put(`/service/status-update`, request);
  }
}

export { ServiceTypeService };
