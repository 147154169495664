import client from 'libs/HttpClient';

class HomeHealthCareService {
  static addHomePageHealthCare(request) {
    return client.post('/homepage/healthcare/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static listHealthCareService(params) {
    return client.get(`/homepage/healthcare/index`, { params });
  }

  static updateHomeHealthCareStatusService(request) {
    return client.put(`/homepage/healthcare/status-update`, request);
  }

  static deleteHomeHealthCare(params) {
    return client.delete(`/homepage/healthcare/delete`, { params });
  }

  static viewHealthCare(request) {
    return client.get(`/homepage/healthcare/view/${request.home_page_heathcare_id}`, request);
  }

  static updateHealthCare(request) {
    return client.post(`/homepage/healthcare/update/${request.home_page_heathcare_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
export { HomeHealthCareService };
