import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListLocationPage = React.lazy(() => import('./ListLocationPage'));
const AddLocationPage = React.lazy(() => import('./AddLocationPage'));
const EditLocationPage = React.lazy(() => import('./EditLocationPage'));

const LocationRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListLocationPage t={t} />} />
      <Route exact path="/add" element={<AddLocationPage t={t} />} />
      <Route exact path="/edit/:location_id" element={<EditLocationPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
LocationRoutes.propTypes = {
  t: PropTypes.func,
};
export default LocationRoutes;
