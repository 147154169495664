import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Footer } from 'common/layouts/Footer/Footer';
import 'assets/scss/_custom.scss';
import { useSelector } from 'react-redux';

import { settingData } from 'store/features/settingSlice';

const LogoUrl = () => {
  const getSettingData = useSelector(settingData);

  /**
   * This will provide  general information from redux store, like logos, and titles
   */
  return (
    <LazyLoadImage
      key={getSettingData?.admin_setting_login_logo}
      placeholderSrc={getSettingData?.admin_setting_login_logo}
      src={getSettingData?.admin_setting_login_logo}
      alt="AOT Delivery"
      // width={'100%'}
      className="nav-brand"
    />
  );
};
const PublicLayout = (props) => {
  // Adding class in body
  useEffect(() => {
    document.body.classList.add('bg-box');
  }, []);
  return (
    <div {...props} className="auth-pages">
      {props.children}
      <Footer />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export { PublicLayout, LogoUrl };
