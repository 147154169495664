import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { SmsService } from 'api';

/**
 * Hooks for Sms And Sms Scheduled pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddSms = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.addSms, {
    onSuccess,
    onError,
  });
};
const useViewSms = (sms_id, onSuccess, onError = onDefaultError) => {
  return useQuery('sms-view', () => SmsService.viewSms({ sms_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateSms = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.updateSms, {
    onSuccess,
    onError,
  });
};
const useAddSmsScheduled = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.addSmsScheduled, {
    onSuccess,
    onError,
  });
};

const useViewSmsScheduled = (sms_scheduled_id, onSuccess, onError = onDefaultError) => {
  return useQuery('sms-view', () => SmsService.viewSmsScheduled({ sms_scheduled_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateSmsScheduled = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.updateSmsScheduled, {
    onSuccess,
    onError,
  });
};
const useListSms = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['sms-list', [params]], () => SmsService.listSms(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useListSmsScheduled = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['sms-scheduled-list', [params]], () => SmsService.listSmsScheduled(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useSmsDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.deleteSms, {
    onSuccess,
    onError,
  });
};
const useSmsScheduledDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmsService.deleteSmsScheduled, {
    onSuccess,
    onError,
  });
};
export {
  useAddSms,
  useViewSms,
  useUpdateSms,
  useAddSmsScheduled,
  useViewSmsScheduled,
  useUpdateSmsScheduled,
  useListSms,
  useListSmsScheduled,
  useSmsDelete,
  useSmsScheduledDelete,
};
