import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CompanyAddressBookRoutes from 'pages/Company/CompanyAddressBook/CompanyAddressbookRoutes';
import CompanyAccountRoutes from 'pages/Company/CompanyAccount/CompanyAccountRoutes';

const CompanyRoutes = ({ t }) => {
  return (
    <>
      <Routes>
        {/* Company Address Book Routes */}
        <Route path="/company-address-book/*" element={<CompanyAddressBookRoutes t={t} />} />
        <Route path="/company-account/*" element={<CompanyAccountRoutes t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
CompanyRoutes.propTypes = {
  t: PropTypes.func,
};
export default CompanyRoutes;
