import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { HomeHealthcareBusinessService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Healthcare Business sections
 */

const useListHealthcareBusiness = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['healthcare-business-list', [params]],
    () => HomeHealthcareBusinessService.listHealthcareBusiness(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddHealthcareBusiness = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthcareBusinessService.addHomePageHealthcareBusiness, {
    onSuccess,
    onError,
  });
};

const useUpdateHealthcareBusiness = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthcareBusinessService.updateHealthcareBusiness, {
    onSuccess,
    onError,
  });
};

const useHealthcareBusinessStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthcareBusinessService.updateHomeHealthcareBusinessStatus, {
    onSuccess,
    onError,
  });
};

const useHealthcareBusinessDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeHealthcareBusinessService.deleteHomeHealthcareBusiness, {
    onSuccess,
    onError,
  });
};

const useViewHealthcareBusiness = (
  home_healthcare_business_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'healthcare-business-view',
    () => HomeHealthcareBusinessService.viewHealthcareBusiness({ home_healthcare_business_id }),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListHealthcareBusiness,
  useAddHealthcareBusiness,
  useUpdateHealthcareBusiness,
  useHealthcareBusinessStatusChange,
  useHealthcareBusinessDelete,
  useViewHealthcareBusiness,
};
