import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import HolidayRoutes from 'pages/Parameters/Holiday/HolidayRoutes';
import ReturnTypeRoutes from 'pages/Parameters/ReturnType/ReturnTypeRoutes';
import ZoneRoutes from 'pages/Parameters/Zone/ZoneRoutes';
import ServiceRoutes from 'pages/Parameters/Service/ServiceRoutes';
import RateRoutes from 'pages/Parameters/Rate/RateRoutes';
import LocationRoutes from 'pages/Parameters/Location/LocationRoutes';
import PriceOptionRoutes from 'pages/Parameters/PriceOption/PriceOptionRoutes';
const ParameterRoutes = ({ t }) => {
  return (
    <Routes>
      {/* Holiday Routes */}
      <Route path="/holiday/*" element={<HolidayRoutes t={t} />} />
      {/* Return Type Routes */}
      <Route path="/return-type/*" element={<ReturnTypeRoutes t={t} />} />
      {/* Zone Routes */}
      <Route path="/zone/*" element={<ZoneRoutes t={t} />} />
      {/* Service Routes */}
      <Route path="/service/*" element={<ServiceRoutes t={t} />} />
      {/* Rate Routes */}
      <Route path="/rate/*" element={<RateRoutes t={t} />} />
      {/* Location Routes */}
      <Route path="/location/*" element={<LocationRoutes t={t} />} />
      {/* Price Option Routes */}
      <Route path="/price-option/*" element={<PriceOptionRoutes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ParameterRoutes.propTypes = {
  t: PropTypes.func,
};
export default ParameterRoutes;
