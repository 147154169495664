import client from 'libs/HttpClient';

class CommonService {
  static getTranslation(request) {
    return client.get(`/common/get-translation-data`, request);
  }
  static getCountryCodeList(request) {
    return client.get('common/country-code-list', request);
  }
  static getZoneList(request) {
    return client.get(`/common/zone-list`, request);
  }
  static getServiceList(request) {
    return client.get(`/common/service-list`, request);
  }
  static getPriceOptionTypeList(request) {
    return client.get(`/common/price-option-type-list`, request);
  }
  static getStateList(request) {
    return client.get(`/common/state-list`, request);
  }
  static getCityList(request) {
    return client.post(`/common/city-list`, request);
  }
  static getLocationList(request) {
    return client.get(`/common/location-list`, request);
  }
  static getDriverUserList(request) {
    return client.get(`/common/driver-user-list`, request);
  }
  static getDispatchList(request) {
    return client.get(`/common/dispatch-list`, request);
  }
  static getTimezoneList(request) {
    return client.get(`/common/timezone-list`, request);
  }
  static getCompanyAccountList(request) {
    return client.get(`/common/company-account-list`, request);
  }
}
export { CommonService };
