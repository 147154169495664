import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { SettingsServices } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Settings sections
 */
const useGetSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data',
    () => {
      return SettingsServices.getSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingsServices.storeSettingData, {
    onSuccess,
    onError,
  });
};

const useGetAdminSettingData = (onSuccess, onError) => {
  return useQuery(
    'admin_setting_data',
    () => {
      return SettingsServices.getAdminSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useStoreAdminSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingsServices.storeAdminSettingData, {
    onSuccess,
    onError,
  });
};

const useGetAdminGeneralSettingData = (onSuccess, onError) => {
  return useQuery(
    'admin_general_setting',
    () => {
      return SettingsServices.getAdminGeneralSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
export {
  useGetSettingDataAlways,
  useStoreSettingData,
  useGetAdminSettingData,
  useStoreAdminSettingData,
  useGetAdminGeneralSettingData,
};
