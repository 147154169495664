import client from 'libs/HttpClient';

class RateService {
  static addRate(request) {
    return client.post('/rate/store', request);
  }
  static updateRate(request) {
    return client.put(`/rate/update/${request.rate_id}`, request);
  }
  static listRate(params) {
    return client.get(`/rate/index`, { params });
  }
  static listAllRate(request) {
    return client.get(`/rate/list-rates`, request);
  }
  static viewRate(request) {
    return client.get(`/rate/view/${request.rate_id}`, request);
  }
  static deleteRate(params) {
    return client.delete(`/rate/delete`, { params });
  }
  static updateStatusRate(request) {
    return client.put(`/rate/status-update`, request);
  }
}
export { RateService };
