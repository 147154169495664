import client from 'libs/HttpClient';

class HomeHealthcareBusinessService {
  static addHomePageHealthcareBusiness(request) {
    return client.post('/homepage/healthcare-business/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static listHealthcareBusiness(params) {
    return client.get(`/homepage/healthcare-business/index`, { params });
  }

  static updateHomeHealthcareBusinessStatus(request) {
    return client.put(`/homepage/healthcare-business/status-update`, request);
  }

  static deleteHomeHealthcareBusiness(params) {
    return client.delete(`/homepage/healthcare-business/delete`, { params });
  }

  static viewHealthcareBusiness(request) {
    return client.get(
      `/homepage/healthcare-business/view/${request.home_healthcare_business_id}`,
      request
    );
  }

  static updateHealthcareBusiness(request) {
    return client.post(
      `/homepage/healthcare-business/update/${request.home_healthcare_business_id}`,
      request,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
  }
}
export { HomeHealthcareBusinessService };
