import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListZonePage = React.lazy(() => import('./ListZonePage'));
const AddZonePage = React.lazy(() => import('./AddZonePage'));
const EditZonePage = React.lazy(() => import('./EditZonePage'));

const ZoneRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListZonePage t={t} />} />
      <Route exact path="/add" element={<AddZonePage t={t} />} />
      <Route exact path="/edit/:zone_id" element={<EditZonePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ZoneRoutes.propTypes = {
  t: PropTypes.func,
};
export default ZoneRoutes;
