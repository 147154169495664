import client from 'libs/HttpClient';

class TranslationService {
  static addTranslation(request) {
    return client.post('/translations/store', request);
  }
  static updateTranslation(request) {
    return client.post(`/translations/update/${request.translation_id}`, request);
  }
  static listTranslation(params) {
    return client.get(`/translations/index`, { params });
  }
  static viewTranslation(request) {
    console.log(request);
    return client.get(`/translations/view/${request.translation_id}`, request);
  }
  static syncTranslation(request) {
    return client.get(`/translations/sync-data`, request);
  }
}
export { TranslationService };
