import client from 'libs/HttpClient';

class WhyChooseUsService {
  static addHomePageWhyChooseUs(request) {
    return client.post('/homepage/whychooseus/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static listWhyChooseUs(params) {
    return client.get(`/homepage/whychooseus/index`, { params });
  }

  static updateHomeWhyChooseUsStatus(request) {
    return client.put(`/homepage/whychooseus/status-update`, request);
  }

  static deleteHomeWhyChooseUs(params) {
    return client.delete(`/homepage/whychooseus/delete`, { params });
  }

  static viewWhyChooseUs(request) {
    return client.get(`/homepage/whychooseus/view/${request.home_why_choose_us_id}`, request);
  }

  static updateWhyChooseus(request) {
    return client.post(`/homepage/whychooseus/update/${request.home_why_choose_us_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
export { WhyChooseUsService };
