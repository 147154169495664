import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { LocationService } from 'api';

/**
 * Hooks for Location pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddLocation = (onSuccess, onError = onDefaultError) => {
  return useMutation(LocationService.addLocation, {
    onSuccess,
    onError,
  });
};

const useListLocation = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['location-list', [params]], () => LocationService.listLocation(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewLocation = (location_id, onSuccess, onError = onDefaultError) => {
  return useQuery('location-view', () => LocationService.viewLocation({ location_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateLocation = (onSuccess, onError = onDefaultError) => {
  return useMutation(LocationService.updateLocation, {
    onSuccess,
    onError,
  });
};
const useLocationDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(LocationService.deleteLocation, {
    onSuccess,
    onError,
  });
};
const useLocationStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(LocationService.updateStatusLocation, {
    onSuccess,
    onError,
  });
};
export {
  useListLocation,
  useAddLocation,
  useViewLocation,
  useUpdateLocation,
  useLocationDelete,
  useLocationStatusChange,
};
