import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListCompanyAddressBookPage = React.lazy(() => import('./ListCompanyAddressBookPage'));
const AddCompanyAddressBookPage = React.lazy(() => import('./AddCompanyAddressBookPage'));
const EditCompanyAddressBookPage = React.lazy(() => import('./EditCompanyAddressBookPage'));

const CompanyAddressBookRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListCompanyAddressBookPage t={t} />} />
      <Route exact path="/add" element={<AddCompanyAddressBookPage t={t} />} />
      <Route
        exact
        path="/edit/:company_address_book_id"
        element={<EditCompanyAddressBookPage t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CompanyAddressBookRoutes.propTypes = {
  t: PropTypes.func,
};
export default CompanyAddressBookRoutes;
