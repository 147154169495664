import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CompanyAddressBookService } from 'api';

/**
 * Hooks for Company Address Book pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddCompanyAddressBook = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAddressBookService.addCompanyAddressBook, {
    onSuccess,
    onError,
  });
};

const useListCompanyAddressBook = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['company-address-book-list', [params]],
    () => CompanyAddressBookService.listCompanyAddressBook(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useViewCompanyAddressBook = (
  company_address_book_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'company-address-book-view',
    () => CompanyAddressBookService.viewCompanyAddressBook({ company_address_book_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdateCompanyAddressBook = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAddressBookService.updateCompanyAddressBook, {
    onSuccess,
    onError,
  });
};
const useCompanyAddressBookDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAddressBookService.deleteCompanyAddressBook, {
    onSuccess,
    onError,
  });
};
const useCompanyAddressBookStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAddressBookService.updateStatusCompanyAddressBook, {
    onSuccess,
    onError,
  });
};
const useGetLatitudeLongitude = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAddressBookService.getLatitudeLongitude, {
    onSuccess,
    onError,
  });
};
export {
  useListCompanyAddressBook,
  useAddCompanyAddressBook,
  useViewCompanyAddressBook,
  useUpdateCompanyAddressBook,
  useCompanyAddressBookDelete,
  useCompanyAddressBookStatusChange,
  useGetLatitudeLongitude,
};
