import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CMSService } from 'api';

/**
 * Hooks for CMS pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(CMSService.addCms, {
    onSuccess,
    onError,
  });
};

const useListCms = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['cms-list', [params]], () => CMSService.listCms(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewCms = (cms_id, onSuccess, onError = onDefaultError) => {
  return useQuery('translation-view', () => CMSService.viewCms({ cms_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(CMSService.updateCms, {
    onSuccess,
    onError,
  });
};
const useCmsStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(CMSService.updateStatusCms, {
    onSuccess,
    onError,
  });
};
const useCmsDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(CMSService.deleteCms, {
    onSuccess,
    onError,
  });
};
export { useListCms, useAddCms, useViewCms, useUpdateCms, useCmsStatusChange, useCmsDelete };
