import client from 'libs/HttpClient';

class CMSService {
  static addCms(request) {
    return client.post('/cms/store', request);
  }
  static updateCms(request) {
    return client.post(`/cms/update/${request.cms_id}`, request);
  }
  static listCms(params) {
    return client.get(`/cms/index`, { params });
  }
  static viewCms(request) {
    console.log(request);
    return client.get(`/cms/view/${request.cms_id}`, request);
  }
  static updateStatusCms(request) {
    return client.put(`/cms/status-update`, request);
  }
  static deleteCms(params) {
    return client.delete(`/cms/delete`, { params });
  }
}
export { CMSService };
