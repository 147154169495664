import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { RateService } from 'api';

/**
 * Hooks for Rate pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddRate = (onSuccess, onError = onDefaultError) => {
  return useMutation(RateService.addRate, {
    onSuccess,
    onError,
  });
};

const useListRate = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['rate-list', [params]], () => RateService.listRate(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useListAllRate = (onSuccess, onError = onDefaultError) => {
  return useQuery('list-rates', RateService.listAllRate, {
    onSuccess,
    onError,
  });
};
const useViewRate = (rate_id, onSuccess, onError = onDefaultError) => {
  return useQuery('rate-view', () => RateService.viewRate({ rate_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateRate = (onSuccess, onError = onDefaultError) => {
  return useMutation(RateService.updateRate, {
    onSuccess,
    onError,
  });
};
const useRateDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(RateService.deleteRate, {
    onSuccess,
    onError,
  });
};
const useRateStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(RateService.updateStatusRate, {
    onSuccess,
    onError,
  });
};
export {
  useListRate,
  useAddRate,
  useViewRate,
  useUpdateRate,
  useRateDelete,
  useRateStatusChange,
  useListAllRate,
};
