import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ServiceTypeService } from 'api';

/**
 * Hooks for Service pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceTypeService.addService, {
    onSuccess,
    onError,
  });
};

const useListService = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['service-list', [params]], () => ServiceTypeService.listService(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewService = (service_id, onSuccess, onError = onDefaultError) => {
  return useQuery('service-view', () => ServiceTypeService.viewService({ service_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceTypeService.updateService, {
    onSuccess,
    onError,
  });
};
const useServiceDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceTypeService.deleteService, {
    onSuccess,
    onError,
  });
};
const useServiceStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceTypeService.updateStatusService, {
    onSuccess,
    onError,
  });
};
export {
  useAddService,
  useListService,
  useViewService,
  useUpdateService,
  useServiceDelete,
  useServiceStatusChange,
};
