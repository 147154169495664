import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ZoneService } from 'api';

/**
 * Hooks for CMS pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddZone = (onSuccess, onError = onDefaultError) => {
  return useMutation(ZoneService.addZone, {
    onSuccess,
    onError,
  });
};

const useListZone = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['zone-list', [params]], () => ZoneService.listZone(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewZone = (zone_id, onSuccess, onError = onDefaultError) => {
  return useQuery('zone-view', () => ZoneService.viewZone({ zone_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateZone = (onSuccess, onError = onDefaultError) => {
  return useMutation(ZoneService.updateZone, {
    onSuccess,
    onError,
  });
};
const useZoneDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(ZoneService.deleteZone, {
    onSuccess,
    onError,
  });
};
const useZoneStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(ZoneService.updateStatusZone, {
    onSuccess,
    onError,
  });
};
export { useListZone, useAddZone, useViewZone, useUpdateZone, useZoneDelete, useZoneStatusChange };
