import client from 'libs/HttpClient';

class HomePharmacySoftwareService {
  static addHomePagePharmacySoftware(request) {
    return client.post('/homepage/pharmacy-software/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static listPharmacySoftware(params) {
    return client.get(`/homepage/pharmacy-software/index`, { params });
  }

  static updateHomePharmacySoftwareStatus(request) {
    return client.put(`/homepage/pharmacy-software/status-update`, request);
  }

  static deleteHomePharmacySoftware(params) {
    return client.delete(`/homepage/pharmacy-software/delete`, { params });
  }

  static viewPharmacySoftware(request) {
    return client.get(
      `/homepage/pharmacy-software/view/${request.home_pharmacy_software_id}`,
      request
    );
  }

  static updatePharmacySoftware(request) {
    return client.post(
      `/homepage/pharmacy-software/update/${request.home_pharmacy_software_id}`,
      request,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
  }
}
export { HomePharmacySoftwareService };
