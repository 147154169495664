import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { WhyChooseUsService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Pharmacy Software sections
 */

const useListWhyChooseUs = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['why-chooseus-list', [params]],
    () => WhyChooseUsService.listWhyChooseUs(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddWhyChooseus = (onSuccess, onError = onDefaultError) => {
  return useMutation(WhyChooseUsService.addHomePageWhyChooseUs, {
    onSuccess,
    onError,
  });
};

const useUpdateWhyChooseUs = (onSuccess, onError = onDefaultError) => {
  return useMutation(WhyChooseUsService.updateWhyChooseus, {
    onSuccess,
    onError,
  });
};

const useWhyChooseUsStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(WhyChooseUsService.updateHomeWhyChooseUsStatus, {
    onSuccess,
    onError,
  });
};

const useWhyChooseUsDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(WhyChooseUsService.deleteHomeWhyChooseUs, {
    onSuccess,
    onError,
  });
};

const useViewWhyChooseUs = (home_why_choose_us_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'why-choose-us-view',
    () => WhyChooseUsService.viewWhyChooseUs({ home_why_choose_us_id }),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListWhyChooseUs,
  useAddWhyChooseus,
  useUpdateWhyChooseUs,
  useWhyChooseUsStatusChange,
  useWhyChooseUsDelete,
  useViewWhyChooseUs,
};
