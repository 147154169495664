import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListSmsPage = React.lazy(() => import('./ListSmsPage'));
const AddSmsPage = React.lazy(() => import('./AddSmsPage'));
const EditSmsPage = React.lazy(() => import('./EditSmsPage'));

const SmsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list" element={<ListSmsPage t={t} />} />
      <Route exact path="/add" element={<AddSmsPage t={t} />} />
      <Route exact path="/edit/:sms_id" element={<EditSmsPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
SmsRoutes.propTypes = {
  t: PropTypes.func,
};
export default SmsRoutes;
