import client from 'libs/HttpClient';

class ZoneService {
  static addZone(request) {
    return client.post('/zone/store', request);
  }
  static updateZone(request) {
    return client.put(`/zone/update/${request.zone_id}`, request);
  }
  static listZone(params) {
    return client.get(`/zone/index`, { params });
  }
  static viewZone(request) {
    return client.get(`/zone/view/${request.zone_id}`, request);
  }
  static deleteZone(params) {
    return client.delete(`/zone/delete`, { params });
  }
  static updateStatusZone(request) {
    return client.put(`/zone/status-update`, request);
  }
}
export { ZoneService };
