import { CommonService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

/**
 * Hook for user Informations after signup
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetCountryCodeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('country-code-list', CommonService.getCountryCodeList, {
    onSuccess,
    onError,
  });
};
const useGetTranslationList = (onSuccess, onError = onDefaultError) => {
  return useQuery('tranlation-list', CommonService.getTranslation, {
    onSuccess,
    onError,
  });
};
const useGetZoneList = (onSuccess, onError = onDefaultError) => {
  return useQuery('zone-list', CommonService.getZoneList, {
    onSuccess,
    onError,
  });
};
const useGetServiceList = (onSuccess, onError = onDefaultError) => {
  return useQuery('service-list', CommonService.getServiceList, {
    onSuccess,
    onError,
  });
};
const useGetPriceOptionTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('price-option-type-list', CommonService.getPriceOptionTypeList, {
    onSuccess,
    onError,
  });
};
const useGetStateList = (onSuccess, onError = onDefaultError) => {
  return useQuery('state-list', CommonService.getStateList, {
    onSuccess,
    onError,
  });
};
const useGetCityList = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommonService.getCityList, {
    onSuccess,
    onError,
  });
};
const useGetLocationList = (onSuccess, onError = onDefaultError) => {
  return useQuery('location-list', CommonService.getLocationList, {
    onSuccess,
    onError,
  });
};
const useGetDriverUserList = (onSuccess, onError = onDefaultError) => {
  return useQuery('driver-user-list', CommonService.getDriverUserList, {
    onSuccess,
    onError,
  });
};
const useGetDispatchList = (onSuccess, onError = onDefaultError) => {
  return useQuery('dispatch-list', CommonService.getDispatchList, {
    onSuccess,
    onError,
  });
};
const useGetCompanyAccountList = (onSuccess, onError = onDefaultError) => {
  return useQuery('company-account-list', CommonService.getCompanyAccountList, {
    onSuccess,
    onError,
  });
};
const useGetTimezoneList = (onSuccess, onError = onDefaultError) => {
  return useQuery('timezone-list', CommonService.getTimezoneList, {
    onSuccess,
    onError,
  });
};

export {
  useGetCountryCodeList,
  useGetTranslationList,
  useGetZoneList,
  useGetServiceList,
  useGetPriceOptionTypeList,
  useGetStateList,
  useGetCityList,
  useGetLocationList,
  useGetDriverUserList,
  useGetDispatchList,
  useGetCompanyAccountList,
  useGetTimezoneList,
};
