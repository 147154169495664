import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListHolidayPage = React.lazy(() => import('./ListHolidayPage'));
const AddHolidayPage = React.lazy(() => import('./AddHolidayPage.js'));
const EditHolidayPage = React.lazy(() => import('./EditHolidayPage'));

const HolidayRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListHolidayPage t={t} />} />
      <Route exact path="/add" element={<AddHolidayPage t={t} />} />
      <Route exact path="/edit/:holiday_id" element={<EditHolidayPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
HolidayRoutes.propTypes = {
  t: PropTypes.func,
};
export default HolidayRoutes;
