import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ReturnTypeService } from 'api';

/**
 * Hooks for CMS pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddReturnType = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReturnTypeService.addReturnType, {
    onSuccess,
    onError,
  });
};

const useListReturnType = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['return-type-list', [params]], () => ReturnTypeService.listReturnType(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useViewReturnType = (return_type_id, onSuccess, onError = onDefaultError) => {
  return useQuery('return-type-view', () => ReturnTypeService.viewReturnType({ return_type_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateReturnType = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReturnTypeService.updateReturnType, {
    onSuccess,
    onError,
  });
};
const useReturnTypeDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReturnTypeService.deleteReturnType, {
    onSuccess,
    onError,
  });
};
const useReturnTypeStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReturnTypeService.updateStatusReturnType, {
    onSuccess,
    onError,
  });
};
export {
  useListReturnType,
  useAddReturnType,
  useViewReturnType,
  useUpdateReturnType,
  useReturnTypeDelete,
  useReturnTypeStatusChange,
};
