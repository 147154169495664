import client from 'libs/HttpClient';

class EmailTemplateService {
  static addEmailTemplate(request) {
    return client.post('/email-template/store', request);
  }
  static listEmailTemplate(params) {
    return client.get(`/email-template/index`, { params });
  }
  static viewEmailTemplate(request) {
    console.log(request);
    return client.get(`/email-template/view/${request.email_template_id}`, request);
  }
  static updateEmailTemplate(request) {
    return client.post(`/email-template/update/${request.email_template_id}`, request);
  }
}
export { EmailTemplateService };
