import client from 'libs/HttpClient';

class HolidayService {
  static addHoliday(request) {
    return client.post('/holiday/store', request);
  }
  static updateHoliday(request) {
    return client.put(`/holiday/update/${request.holiday_id}`, request);
  }
  static listHoliday(params) {
    return client.get(`/holiday/index`, { params });
  }
  static viewHoliday(request) {
    return client.get(`/holiday/view/${request.holiday_id}`, request);
  }
  static deleteHoliday(params) {
    return client.delete(`/holiday/delete`, { params });
  }
  static updateStatusHoliday(request) {
    return client.put(`/holiday/status-update`, request);
  }
  static listAllHoliday(params) {
    return client.get('/holiday/list', { params });
  }
}
export { HolidayService };
