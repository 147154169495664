import client from 'libs/HttpClient';

class LocationService {
  static addLocation(request) {
    return client.post('/location/store', request);
  }
  static updateLocation(request) {
    return client.put(`/location/update/${request.location_id}`, request);
  }
  static listLocation(params) {
    return client.get(`/location/index`, { params });
  }
  static viewLocation(request) {
    return client.get(`/location/view/${request.location_id}`, request);
  }
  static deleteLocation(params) {
    return client.delete(`/location/delete`, { params });
  }
  static updateStatusLocation(request) {
    return client.put(`/location/status-update`, request);
  }
}
export { LocationService };
