import client from 'libs/HttpClient';

class CompanyAccountService {
  static listCompanyAccountService(params) {
    return client.get(`/company-account/index`, { params });
  }
  static addCompanyAccount(request) {
    return client.post('/company-account/store-company-account-info', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static updateStatusCompanyAccount(request) {
    return client.put(`/company-account/status-update`, request);
  }
  static viewCompanyAccountInfo(params) {
    return client.get(`/company-account/view-company-account-info/${params.company_account_id}`);
  }
  static viewCompanyAccountService(params) {
    return client.get('/company-account/view-company-account-service', { params });
  }
  static addCompanyAccountService(request) {
    return client.post('/company-account/store-company-account-service', request);
  }
  static addCompanyAccountRate(request) {
    return client.post('/company-account/store-company-account-rate', request);
  }
  static addCompanyAccountDriverPayBreakdown(request) {
    return client.put('/company-account/store-company-account-driver-pay-breakdown', request);
  }
  static addCompanyAccountPrice(request) {
    return client.put('/company-account/store-company-account-price', request);
  }
  static viewCompanyAccountPrice(params) {
    return client.get(`/company-account/view-company-account-price/${params.company_account_id}`);
  }
  static addCompanyAccountSetting(request) {
    return client.put('/company-account/store-company-account-setting', request);
  }
  static addCompanyAccountHoliday(request) {
    return client.post('/company-account/store-company-account-holiday', request);
  }
}
export { CompanyAccountService };
