import { AlertCommon } from 'common/components';
import React, { useState } from 'react';

const DashboardPage = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      <h1>Dashboard Page</h1>
      {localStorage.success_message ? (
        <AlertCommon
          show={show}
          onHide={setTimeout(() => {
            localStorage.success_message = '';
            setShow(false);
          }, 3000)}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
          type="success"
          is_error={true}>
          {localStorage.success_message}
        </AlertCommon>
      ) : (
        ''
      )}
    </>
  );
};

export { DashboardPage };
