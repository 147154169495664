import client from 'libs/HttpClient';

class DriverService {
  static addDriverInfo(request) {
    return client.post('/driver/driver-info/store', request);
  }
  static updateDriverInfo(request) {
    return client.put(`/driver/driver-info/update/${request.user_id}`, request);
  }
  static listDriverInfo(params) {
    return client.get(`/driver/driver-info/index`, { params });
  }
  static viewDriverInfo(request) {
    return client.get(`/driver/driver-info/view/${request.user_id}`, request);
  }
  static deleteDriverInfo(params) {
    return client.delete(`/driver/driver-info/delete`, { params });
  }
  static updateStatusDriverInfo(request) {
    return client.put(`/driver/driver-info/status-update`, request);
  }
  static viewDriverData(request) {
    return client.get(`/driver/driver-info/view_driver/${request.user_id}`, request);
  }
  static updateDriverEmail(request) {
    return client.put('driver/driver-info/update-email', request);
  }
  static updateDriverEmailVerify(request) {
    return client.put('driver/driver-info/verify-email', request);
  }
  static resendOtp(request) {
    return client.put('driver/driver-info/verify-resend-email', request);
  }
}
export { DriverService };
