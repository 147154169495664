import client from 'libs/HttpClient';

class ReturnTypeService {
  static addReturnType(request) {
    return client.post('/return-type/store', request);
  }
  static updateReturnType(request) {
    return client.put(`/return-type/update/${request.return_type_id}`, request);
  }
  static listReturnType(params) {
    return client.get(`/return-type/index`, { params });
  }
  static viewReturnType(request) {
    return client.get(`/return-type/view/${request.return_type_id}`, request);
  }
  static deleteReturnType(params) {
    return client.delete(`/return-type/delete`, { params });
  }
  static updateStatusReturnType(request) {
    return client.put(`/return-type/status-update`, request);
  }
}
export { ReturnTypeService };
