import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import DriverInfoRoutes from 'pages/Driver/DriverInfo/DriverInfoRoutes';

const DriverRoutes = ({ t }) => {
  return (
    <Routes>
      {/* Company Address Book Routes */}
      <Route path="/driver-info/*" element={<DriverInfoRoutes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
DriverRoutes.propTypes = {
  t: PropTypes.func,
};
export { DriverResetPasswordPage } from './DriverInfo/DriverResetPasswordPage/DriverResetPasswordPage';
export default DriverRoutes;
