import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AdminSettingPage = React.lazy(() => import('pages/Settings/AdminSetting/AdminSettingPage'));

const AdminSettingRoutes = ({ t }) => {
  return (
    <Routes>
      {/* Admin General Setting Routes */}
      <Route index exact path="/" element={<AdminSettingPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AdminSettingRoutes.propTypes = {
  t: PropTypes.func,
};
export default AdminSettingRoutes;
