import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListReturnTypePage = React.lazy(() => import('./ListReturnTypePage'));
const AddReturnTypePage = React.lazy(() => import('./AddReturnTypePage.js'));
const EditReturnTypePage = React.lazy(() => import('./EditReturnTypePage'));

const ReturnTypeRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListReturnTypePage t={t} />} />
      <Route exact path="/add" element={<AddReturnTypePage t={t} />} />
      <Route exact path="/edit/:return_type_id" element={<EditReturnTypePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ReturnTypeRoutes.propTypes = {
  t: PropTypes.func,
};
export default ReturnTypeRoutes;
