import client from 'libs/HttpClient';

class CompanyAddressBookService {
  static addCompanyAddressBook(request) {
    return client.post('/company/company-address-book/store', request);
  }
  static updateCompanyAddressBook(request) {
    return client.put(
      `/company/company-address-book/update/${request.company_address_book_id}`,
      request
    );
  }
  static listCompanyAddressBook(params) {
    return client.get(`/company/company-address-book/index`, { params });
  }
  static viewCompanyAddressBook(request) {
    return client.get(
      `/company/company-address-book/view/${request.company_address_book_id}`,
      request
    );
  }
  static deleteCompanyAddressBook(params) {
    return client.delete(`/company/company-address-book/delete`, { params });
  }
  static updateStatusCompanyAddressBook(request) {
    return client.put(`/company/company-address-book/status-update`, request);
  }
  static getLatitudeLongitude(request) {
    return client.post(`/company/company-address-book/get-lat-long`, request);
  }
}
export { CompanyAddressBookService };
