import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListCompanyAccountPage = React.lazy(() => import('./ListCompanyAccountPage'));
const EditCompanyAccountInfo = React.lazy(() =>
  import('./CompanyAccountInfo/EditCompanyAccountInfo')
);
const AddCompanyAccountInfo = React.lazy(() =>
  import('./CompanyAccountInfo/AddCompanyAccountInfo')
);
const AddCompanyAccountService = React.lazy(() =>
  import('./CompanyAccountService/AddCompanyAccountService')
);
const AddCompanyAccountRate = React.lazy(() =>
  import('./CompanyAccountRate/AddCompanyAccountRate')
);
const AddCompanyAccountDriverPayBreakdown = React.lazy(() =>
  import('./CompanyAccountDriverPayBreakdown/AddCompanyAccountDriverPayBreakdown')
);
const AddCompanyAccountPrice = React.lazy(() =>
  import('./CompanyAccountPrice/AddCompanyAccountPrice')
);
const AddCompanyAccountSetting = React.lazy(() =>
  import('./CompanyAccountSetting/AddCompanyAccountSetting')
);
const AddCompanyAccountHoliday = React.lazy(() =>
  import('./CompanyAccountHoliday/AddCompanyAccountHoliday')
);

const CompanyAccountRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListCompanyAccountPage t={t} />} />
      <Route
        index
        exact
        path="/edit-company-account-info/:company_account_id"
        element={<EditCompanyAccountInfo t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-info"
        element={<AddCompanyAccountInfo t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-service/:company_account_id/:is_new"
        element={<AddCompanyAccountService t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-rate/:company_account_id"
        element={<AddCompanyAccountRate t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-driver-pay/:company_account_id"
        element={<AddCompanyAccountDriverPayBreakdown t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-price/:company_account_id"
        element={<AddCompanyAccountPrice t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-setting/:company_account_id"
        element={<AddCompanyAccountSetting t={t} />}
      />
      <Route
        index
        exact
        path="/add-company-account-holiday/:company_account_id"
        element={<AddCompanyAccountHoliday t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CompanyAccountRoutes.propTypes = {
  t: PropTypes.func,
};
export default CompanyAccountRoutes;
