import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * CMS Pages and routes
 */
const ListPriceOptionPage = React.lazy(() => import('./ListPriceOptionPage'));
const AddPriceOptionPage = React.lazy(() => import('./AddPriceOptionPage'));
const EditPriceOptionPage = React.lazy(() => import('./EditPriceOptionPage'));

const PriceOptionRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListPriceOptionPage t={t} />} />
      <Route exact path="/add" element={<AddPriceOptionPage t={t} />} />
      <Route exact path="/edit/:price_option_id" element={<EditPriceOptionPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PriceOptionRoutes.propTypes = {
  t: PropTypes.func,
};
export default PriceOptionRoutes;
