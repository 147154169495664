import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { CompanyAccountService } from 'api';

/**
 * Hooks for Company Address Book pages
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListCompanyAccount = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['company-account-list', [params]],
    () => CompanyAccountService.listCompanyAccountService(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useAddCompanyAccountInfo = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccount, {
    onSuccess,
    onError,
  });
};
const useCompanyAccountStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.updateStatusCompanyAccount, {
    onSuccess,
    onError,
  });
};
const useViewCompanyAccountInfo = (company_account_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-company-account-info',
    () => CompanyAccountService.viewCompanyAccountInfo({ company_account_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useViewCompanyAccountService = (request, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['view-company-account-service', [request]],
    () => CompanyAccountService.viewCompanyAccountService(request),
    {
      onSuccess,
      onError,
    }
  );
};
const useAddCompanyAccountService = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountService, {
    onSuccess,
    onError,
  });
};
const useAddCompanyAccountRate = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountRate, {
    onSuccess,
    onError,
  });
};
const useAddCompanyAccountDriverPayBreakdown = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountDriverPayBreakdown, {
    onSuccess,
    onError,
  });
};
const useAddCompanyAccountPrice = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountPrice, {
    onSuccess,
    onError,
  });
};
const useAddCompanyAccountSetting = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountSetting, {
    onSuccess,
    onError,
  });
};
const useAddCompanyAccountHoliday = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompanyAccountService.addCompanyAccountHoliday, {
    onSuccess,
    onError,
  });
};
const useViewCompanyAccountPrice = (company_account_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-company-account-price',
    () => CompanyAccountService.viewCompanyAccountPrice({ company_account_id }),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListCompanyAccount,
  useCompanyAccountStatusChange,
  useViewCompanyAccountInfo,
  useAddCompanyAccountInfo,
  useAddCompanyAccountService,
  useAddCompanyAccountRate,
  useViewCompanyAccountService,
  useAddCompanyAccountDriverPayBreakdown,
  useAddCompanyAccountPrice,
  useAddCompanyAccountSetting,
  useAddCompanyAccountHoliday,
  useViewCompanyAccountPrice,
};
