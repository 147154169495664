import client from 'libs/HttpClient';

class HomeTestimonialService {
  static addHomePageTestimonial(request) {
    return client.post('/homepage/testimonial/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static listTestimonial(params) {
    return client.get(`/homepage/testimonial/index`, { params });
  }

  static updateHomeTestimonialStatus(request) {
    return client.put(`/homepage/testimonial/status-update`, request);
  }

  static deleteHomeTestimonial(params) {
    return client.delete(`/homepage/testimonial/delete`, { params });
  }

  static viewTestimonial(request) {
    return client.get(`/homepage/testimonial/view/${request.testimonial_id}`, request);
  }

  static updateTestimonial(request) {
    return client.post(`/homepage/testimonial/update/${request.testimonial_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
export { HomeTestimonialService };
