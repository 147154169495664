import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const HealthCarePageRoutes = React.lazy(() =>
  import('pages/Settings/HomePage/HomeHealthCare/HealthCarePageRoutes')
);
const PharmacySoftwarePageRoutes = React.lazy(() =>
  import('pages/Settings/HomePage/HomePharmacySoftware/PharmacySoftwareRoutes')
);
const TestimonialPageRoutes = React.lazy(() =>
  import('pages/Settings/HomePage/HomeTestimonial/TestimonialRoutes')
);
const GeneralSettingPage = React.lazy(() => import('pages/Settings/GeneralSettingPage'));

const WhyChooseUsRoutes = React.lazy(() =>
  import('pages/Settings/HomePage/HomeWhyChooseus/WhyChooseUsRoutes')
);
const HealthcareBusinessRoutes = React.lazy(() =>
  import('pages/Settings/HomePage/HomeHealthcareBusiness/HealthcareBusinessRoutes')
);
const HomePageRoutes = ({ t }) => {
  return (
    <Routes>
      {/* Healthcare Routes */}
      {/* <Route path="/healthcare/*" element={<HealthCarePageRoutes t={t} />} /> */}
      <Route index exact path="/healthcare/*" element={<HealthCarePageRoutes t={t} />} />
      {/* Pharmacy Software Routes */}
      <Route path="/pharmacy-software/*" element={<PharmacySoftwarePageRoutes t={t} />} />
      {/* Testimonial Routes */}
      <Route path="/testimonial/*" element={<TestimonialPageRoutes t={t} />} />
      {/* General Setting Routes */}
      <Route exact path="/general" element={<GeneralSettingPage t={t} />} />
      {/* WhyChooseUs Routes */}
      <Route path="/why-choose-us/*" element={<WhyChooseUsRoutes t={t} />} />
      {/* Healthcare Business Routes */}
      <Route path="/healthcare-business/*" element={<HealthcareBusinessRoutes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
HomePageRoutes.propTypes = {
  t: PropTypes.func,
};
export default HomePageRoutes;
