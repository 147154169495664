import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

function AlertCommon(props) {
  const [show, setShow] = useState(true);
  if (!props.is_error) {
    return null;
  }
  let variant = 'danger';
  let icon = faCircleExclamation;
  if (props.type) {
    variant = props.type;
    if (props.type === 'error') {
      variant = 'danger';
      icon = faCircleExclamation;
    }
    if (props.type === 'success') {
      variant = 'success';
      icon = faCircleCheck;
    }
  }
  if (show) {
    return (
      <Alert
        key={props.type}
        className="mx-0 alert-box"
        onClose={() => {
          setShow(false);
        }}
        variant={variant}
        dismissible>
        <FontAwesomeIcon icon={icon} className="pe-2" />
        {props.children !== undefined &&
        props.children !== null &&
        props.children.message !== undefined
          ? props.children.message
          : props.children}
      </Alert>
    );
  }
}

AlertCommon.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
  is_error: PropTypes.any,
  type: PropTypes.any,
};
export default AlertCommon;
