import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { HomePharmacySoftwareService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Pharmacy Software sections
 */

const useListPharmacySoftware = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['pharmacy-software-list', [params]],
    () => HomePharmacySoftwareService.listPharmacySoftware(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddPharmacySoftware = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomePharmacySoftwareService.addHomePagePharmacySoftware, {
    onSuccess,
    onError,
  });
};

const useUpdatePharmacySoftware = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomePharmacySoftwareService.updatePharmacySoftware, {
    onSuccess,
    onError,
  });
};

const usePharmacySoftwareStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomePharmacySoftwareService.updateHomePharmacySoftwareStatus, {
    onSuccess,
    onError,
  });
};

const usePharmacySoftwareDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomePharmacySoftwareService.deleteHomePharmacySoftware, {
    onSuccess,
    onError,
  });
};

const useViewPharmacySoftware = (
  home_pharmacy_software_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'pharmacy-software-view',
    () => HomePharmacySoftwareService.viewPharmacySoftware({ home_pharmacy_software_id }),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListPharmacySoftware,
  useAddPharmacySoftware,
  useUpdatePharmacySoftware,
  usePharmacySoftwareStatusChange,
  usePharmacySoftwareDelete,
  useViewPharmacySoftware,
};
