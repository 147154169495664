import client from 'libs/HttpClient';

class AdminAuthService {
  static login(loginData) {
    return client.post('auth/login', loginData);
  }
  static forgotPassword(request) {
    return client.put('auth/forget-password', request);
  }
  static resendOtp(request) {
    return client.put('auth/resend-otp', request);
  }
  static otpVerify(request) {
    return client.put('auth/verify-otp', request);
  }
  static resetPassword(request) {
    return client.patch('auth/reset-password', request);
  }
}

export { AdminAuthService };
